@import "/src/color.scss";

.answer {
  background: $bg-light-grey;
  padding: 30px 38px;
  box-sizing: border-box;
  color: $text-dark-grey;
}

@media (max-width: 1000px) {
  .answer {
    line-height: 1.5;
  }
}

@media (max-width: 768px) {
  .answer {
    padding: 24px 20px;
    font-size: 14px;
    font-weight: 500;
  }
}
