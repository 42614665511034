body {
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  font-family: "Pretendard";
  font-weight: 400;
  font-style: normal;
  box-sizing: border-box;

  p {
    margin: 0;
  }
}

@font-face {
  font-family: "Pretendard";
  src: url(./assets/font/pretendard/Pretendard-Regular.otf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url(./assets/font/pretendard/Pretendard-Medium.otf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url(./assets/font/pretendard/Pretendard-Bold.otf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url(./assets/font/pretendard/Pretendard-ExtraBold.otf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url(./assets/font/pretendard/Pretendard-Black.otf);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/font/poppins/Poppins-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/font/poppins/Poppins-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/font/poppins/Poppins-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/font/poppins/Poppins-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}
