@import "/src/color.scss";
@import "/src/common.scss";

.logo_container {
  display: none;
}

@media (max-width: 1100px) {
  .logo_container {
    display: flex;
    flex-wrap: wrap;
    margin: 80px auto 0;
    gap: 25px;
    width: 500px;

    .logo {
      width: 230px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 768px) {
  .logo_container {
    margin: 50px auto 0;
    gap: 16px;
    width: 335px;

    .logo {
      width: 156px;
      height: 60px;
    }
  }
}
