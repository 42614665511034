.management_solution {
  width: 100%;
  display: flex;
  .our_core_values {
    position: relative;
    width: 50%;
    height: 1000px;
    overflow: hidden;
    background-image: url(../../../assets/image/ourCoreValuesBackground.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .background_overlay {
      width: 100%;
      height: 100%;
      background-color: #0097ffd4;
      z-index: 1;
    }
    .text_container {
      padding: 230px 100px;
      height: 100%;
      width: 100%;
      position: absolute;
      box-sizing: border-box;
      z-index: 2;
      word-break: keep-all;

      h4 {
        margin: 0;
        color: var(--FFFFFF, #fff);
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.5;
        text-transform: uppercase;
      }

      h3 {
        color: var(--FFFFFF, #fff);
        text-align: center;
        font-size: 50px;
        font-weight: 800;
        text-transform: uppercase;
      }

      p {
        color: var(--FFFFFF, #fff);
        text-align: center;
        font-size: 20px;
        line-height: 1.5;
      }
    }
    .buttons_container {
      position: absolute;
      box-sizing: border-box;
      align-items: flex-end;
      padding: 200px 100px;
      width: 100%;
      height: 100%;
      z-index: 2;
      word-break: keep-all;
      display: flex;

      .buttons {
        margin: 0 auto;
        width: 900px;
        display: flex;
        flex-wrap: wrap;

        ul {
          width: 100%;
          display: flex;
          padding: 0;
          margin: 0;
          list-style: none;
          flex-wrap: wrap;
          justify-content: center;

          li {
            width: 30%;
            margin: 12px 10px;
            cursor: pointer;
            transition: all 0.3s ease;
            text-align: center;

            button {
              width: 100%;
              border: none;
              background: #ffffff;
              padding: 16px;
              border-radius: 80px;
              cursor: pointer;
              transition: all 0.3s ease;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: pretendard;
              font-size: 17px;
              font-weight: 700;
              color: #333c49;
            }

            &.slick_active button {
              background: #333c49;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .slider_container {
    position: relative;
    width: 50%;
    height: 1000px;
    overflow: hidden;

    .mobile_background {
      position: absolute;
      width: 100%;
      height: 90%;
      background-color: #0080da;
      display: none;
    }
    .slide_style {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
    }

    .prev_arrow,
    .next_arrow {
      position: absolute;
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 10000;
    }

    .prev_arrow {
      left: 7%;
    }

    .next_arrow {
      right: 7%;
    }

    .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    h3 {
      display: flex;
      flex-direction: column;
      margin: 0;
      color: #0097ff;
      text-align: center;
      font-size: 50px;
      font-weight: 800;
      text-transform: uppercase;
    }

    span {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 60px;
      color: #6d7683;
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-weight: 400;
    }

    img {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      display: block;
      margin: auto;
    }
  }
}

@media (max-width: 1800px) {
  .management_solution {
    .our_core_values {
      .text_container {
        padding: 200px 0px;
      }
      .buttons_container {
        padding: 200px 30px;
        .buttons {
          width: 100%;
        }
      }
    }
    .slider_container {
      img {
        width: 90%;
      }
      .prev_arrow {
        left: 3%;
      }

      .next_arrow {
        right: 3%;
      }
    }
  }
}

@media (max-width: 1440px) {
  .management_solution {
    .our_core_values {
      .text_container {
        padding: 200px 40px;
      }
      .buttons_container {
        padding: 150px 30px;
        .buttons {
          ul {
            li {
              width: 46%;
              margin: 10px 8px;
              cursor: pointer;
              transition: all 0.3s ease;
              text-align: center;

              button {
                font-size: 15px;
              }
            }
          }
        }
        .slider_container {
          .prev_arrow {
            left: 1%;
          }

          .next_arrow {
            right: 1%;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .management_solution {
    flex-direction: column;
    .our_core_values {
      width: 100%;
      height: 600px;

      .text_container {
        padding: 60px 20px;

        h4 {
          font-size: 18px;
        }

        h3 {
          font-size: 35px;
        }

        p {
          font-size: 18px;
        }
      }
      .buttons_container {
        padding: 30px 0;

        .buttons {
          width: 80%;
          ul {
            li {
              width: 35%;
              margin: 8px 6px;
              cursor: pointer;
              transition: all 0.3s ease;
              text-align: center;

              button {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
    .slider_container {
      width: 100%;
      height: 600px;

      .mobile_background {
        display: block;
      }

      .slide_style {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f5f5;
      }

      .prev_arrow,
      .next_arrow {
        position: absolute;
        width: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 10000;
      }

      .prev_arrow {
        left: 12%;
      }

      .next_arrow {
        right: 12%;
      }

      h3 {
        margin-top: 50px;
        font-size: 35px;
      }

      span {
        margin: 20px;
        font-size: 18px;
      }

      img {
        display: flex;
        flex-direction: column;
        max-width: 60%;
        max-height: 60%;
        object-fit: contain;
        display: block;
        margin: auto;
      }
    }
  }
}

@media (max-width: 675px) {
  .management_solution {
    .our_core_values {
      height: 570px;
      .text_container {
        padding: 40px 20px;
        h4 {
          font-size: 15px;
        }

        h3 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }
      }
      .buttons_container {
        padding: 40px 0;

        .buttons {
          width: 50%;
          ul {
            li {
              width: 180px;
              margin: 8px 6px;
              cursor: pointer;
              transition: all 0.3s ease;
              text-align: center;

              button {
                font-size: 12px;
                padding: 11px 5px;
              }
            }
          }
        }
      }
    }
    .slider_container {
      width: 100%;
      height: 500px;

      .slide_style {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f5f5;
      }

      .prev_arrow,
      .next_arrow {
        position: absolute;
        width: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 10000;
      }

      .prev_arrow {
        left: 5%;
      }

      .next_arrow {
        right: 5%;
      }

      h3 {
        margin-top: 0px;
        font-size: 20px;
      }

      span {
        margin: 10px;
        margin-bottom: 30px;
        font-size: 16px;
      }

      img {
        display: flex;
        flex-direction: column;
        max-width: 75%;
        max-height: 75%;
        object-fit: contain;
        display: block;
        margin: auto;
      }
    }
  }
}
