.participating_companies_container {
  padding: 160px 0;
}

@media (max-width: 1080px) {
  .participating_companies_container {
    padding: 160px 0;
  }
}

@media (max-width: 768px) {
  .participating_companies_container {
    padding: 196px 20px;
  }
}
