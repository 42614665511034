@import "/src/color.scss";
@import "/src/common.scss";

.support_container {
  @include flex-column-center;
  width: 100%;
  height: 490px;
  padding: 130px 300px;
  box-sizing: border-box;
  background: url("../../../assets/image/support.gif") center/cover no-repeat;

  .title {
    font-size: 45px;
    font-weight: 800;
    color: white;
  }

  .sub_title {
    margin-top: 24px;
    font-size: 20px;
    color: white;
  }

  .buttons_container {
    display: flex;
    gap: 20px;
    margin-top: 64px;

    button {
      all: unset;
      cursor: pointer;
      @include justi-between-align-center;
      width: 200px;
      padding: 16px 32px;
      box-sizing: border-box;
      border-radius: 80px;
      background: $point-skyblue-500;
      font-size: 17px;
      font-weight: 700;
      color: white;
    }

    .inquiry {
      background: white;
      color: $point-skyblue-500;
    }
  }
}

@media (max-width: 1240px) {
  .support_container {
    padding: 130px 130px;
  }
}

@media (max-width: 768px) {
  .support_container {
    padding: 60px 16px;
    .title {
      font-size: 24px;
    }

    .sub_title {
      margin-top: 14px;
      font-size: 15px;
    }

    .buttons_container {
      gap: 10px;
      margin-top: 30px;

      button {
        width: 145px;
        padding: 10px 20px;
        font-size: 13px;
      }
    }
  }
}
