.slider_container {
  pointer-events: none;
  margin-top: 100px;
}

@media (max-width: 1560px) {
  .slider_container {
    .logo {
      img {
        width: 250px;
      }
    }
  }

  .slider_container:nth-of-type(2) {
    margin-top: 60px;
  }
}

@media (max-width: 1560px) {
  .slider_container {
    .logo {
      img {
        width: 210px;
      }
    }
  }

  .slider_container:nth-of-type(2) {
    margin-top: 50px;
  }
}

@media (max-width: 1000px) {
  .slider_container {
    .logo {
      img {
        width: 180px;
      }
    }
  }

  .slider_container:nth-of-type(2) {
    margin-top: 65px;
  }
}
@media (max-width: 1100px) {
  .sliders_container {
    display: none;
  }
}
