@import "/src/color.scss";

@keyframes smoothBackground {
  0% {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
  }
  20% {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0.4px 0.8px 0px rgba(0, 0, 0, 0.01);
  }
  40% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0.8px 1.6px 0px rgba(0, 0, 0, 0.02);
  }
  60% {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 1.2px 2.4px 0px rgba(0, 0, 0, 0.03);
  }
  80% {
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 1.6px 3.2px 0px rgba(0, 0, 0, 0.04);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  }
}

.container {
  box-sizing: border-box;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 40px 160px;
  z-index: 100;
  transition: all 0.01s;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;

  &.scrolled {
    animation: smoothBackground 0.5s ease forwards;

    .transparent_button {
      color: $text-dark-grey;
    }

    .login_button {
      color: $text-dark-grey;
    }
  }
}

.logo_container {
  display: flex;
  align-items: center;
  position: relative;
  width: 225px;
  height: 42px;
}

.logo,
.logo_colored {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.logo_colored {
  display: none;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.button_container {
  display: flex;
  gap: 100px;

  .transparent_button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-family: "Pretendard", sans-serif;
    font-weight: 700;
    font-size: 17px;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
    transition: color 0.1s ease;

    &.active {
      color: $point-skyblue-500;
    }
  }
}

.login_container {
  width: 225px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;

  .icon {
    width: auto;
    height: auto;
  }

  .login_button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-family: "Pretendard", sans-serif;
    font-weight: 400;
    font-size: 17px;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
    transition: color 0.3s ease;
  }
}

@media (max-width: 1440px) {
  .container {
    padding: 40px 80px;
  }
  .button_container {
    display: flex;
    gap: 60px;
  }

  .logo_container {
    width: 202px;
  }

  .logo,
  .logo_colored {
    width: 90%;
  }
  .login_container {
    width: 202px;
  }
}

@media (max-width: 1200px) {
  .login_container {
    width: 150px;
  }
}

@media (max-width: 1000px) {
  .container {
    padding: 16px 16px;
    height: auto;
  }

  .button_container {
    display: none;
  }

  .login_container {
    width: 150px;
    .icon {
      width: 30px;
      height: 30px;
    }

    .login_button {
      color: transparent;
      font-size: 0px;
    }
    &.active {
      color: transparent;
    }
  }

  .logo_container {
    width: 343px;
    height: 43px;
  }

  .logo,
  .logo_colored {
    width: auto;
    height: 90%;
  }
}
