$border-light-grey: #e5e8ed;
$bg-light-grey: #f5f6fa;

// text
$text-black: #171a1f;
$text-dark-grey: #333c49;
$text-mid-grey: #6d7683;

// point
$point-skyblue-500: #0097ff;
