.faq_container {
  padding: 0 200px 160px;
  box-sizing: border-box;

  .faq_list {
    margin-top: 50px;
  }
}

@media (max-width: 1240px) {
  .faq_container {
    padding: 0 80px 160px;
  }
}

@media (max-width: 1000px) {
  .faq_container {
    padding: 0 50px 160px;
  }
}

@media (max-width: 768px) {
  .faq_container {
    padding: 0 40px 160px;
    .faq_list {
      margin-top: 30px;
    }
  }
}
