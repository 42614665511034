.introduction {
  width: 100vw;
  z-index: -1;

  .slider_container {
    box-sizing: border-box;
    width: 100%;
    z-index: 1;

    .slick_container {
      position: absolute;
      z-index: 2;
      bottom: 32%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 30px;
      display: flex;
      justify-items: center;
      align-items: center;
      .numbers_container {
        z-index: 1;
        transform: translate(3%);
        width: 126px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          color: #ffffff;
          text-align: center;
          font-family: Poppins;
          font-size: 20px;
          font-weight: 700;
        }
      }
      input {
        -webkit-appearance: none;
        appearance: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(255, 255, 255, 0.3);
        width: 60px;

        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 3px;
          cursor: pointer;
          border-radius: 5px;
          position: relative;
          z-index: 1;
          background: linear-gradient(
            to right,
            white 30px,
            rgba(255, 255, 255, 0.3) 30px
          );
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 30px;
          height: 3px;
          background: white;
          cursor: pointer;
          border-radius: 0;
          z-index: 2;
          position: relative;
          left: 0;
          transform: translateX(0);
        }
      }
    }

    .first_slide {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      background-image: url("../../../assets/image/mysoIntroBackgroundVideo.gif");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;

      .text_container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        width: 100%;
        height: 38%;
        word-break: keep-all;
        text-align: center;

        h2 {
          margin: 0;
          font-size: 70px;
          font-weight: 900;
        }

        p {
          margin-top: 60px;
          font-size: 20px;
        }
      }
    }

    .second_slide {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      background-image: url("../../../assets/image/mysoIntroBackgroundVideo2.gif");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;

      .text_container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        width: 100%;
        height: 38%;
        word-break: keep-all;
        text-align: center;

        h2 {
          margin: 0;
          font-size: 70px;
          font-weight: 900;
        }

        p {
          margin-top: 60px;
          font-size: 20px;
        }
      }
    }
  }
  .bottom_container {
    padding: 0 150px;
    z-index: 2;
    box-sizing: border-box;
    width: 100vw;
    height: 24%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    display: flex;
    word-break: keep-all;
    justify-content: space-between;

    .bottom_our_core_values {
      width: 45%;
      display: flex;
      align-items: center;
      gap: 50px;
      justify-content: flex-end;

      .our_core_values_text_container {
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .our_core_values_text {
          color: #ffffff;
          font-family: Poppins;
          font-size: 24px;
          font-weight: 700;
        }

        .our_core_values_subtext {
          color: #ffffff;
          font-family: Poppins;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }

    .bottom_contact_us {
      width: 45%;
      display: flex;
      align-items: center;
      gap: 50px;

      .contact_us_text_container {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .contact_us_text {
          color: #ffffff;
          font-family: Poppins;
          font-size: 24px;
          font-weight: 700;
        }

        .contact_us_subtext {
          color: #ffffff;
          font-family: Poppins;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .introduction {
    .slider_container {
      .slick_container {
        bottom: 35%;
        .numbers_container {
          span {
            font-size: 18px;
          }
        }
      }
      .first_slide {
        .text_container {
          top: 53%;
          h2 {
            font-size: 50px;
          }
          p {
            font-size: 18px;
          }
        }
      }
      .second_slide {
        .text_container {
          top: 53%;
          h2 {
            font-size: 50px;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
    .bottom_container {
      overflow: hidden;
      padding: 40px 16px;
      .bottom_our_core_values {
        flex-direction: column;
        gap: 16px;
        img {
          height: 60px;
          width: 60px;
        }
        justify-content: flex-start;
        .our_core_values_text_container {
          text-align: center;
          width: 80%;
          .our_core_values_text {
            font-size: 14px;
          }
          .our_core_values_subtext {
            font-size: 13px;
          }
        }
      }

      .bottom_contact_us {
        flex-direction: column;
        gap: 16px;
        img {
          height: 60px;
          width: 60px;
        }
        .contact_us_text_container {
          width: 80%;
          text-align: center;
          .contact_us_text {
            font-size: 14px;
          }
          .contact_us_subtext {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .introduction {
    .slider_container {
      .slick_container {
        .numbers_container {
          span {
            font-size: 14px;
          }
        }
      }
      .first_slide {
        .text_container {
          h2 {
            font-size: 28px;
          }
          p {
            font-size: 15px;
          }
        }
      }
      .second_slide {
        .text_container {
          h2 {
            font-size: 28px;
          }
          p {
            font-size: 15px;
          }
        }
      }
    }
    .bottom_container {
      overflow: hidden;
      padding: 40px 16px;
      .bottom_our_core_values {
        flex-direction: column;
        gap: 16px;
        img {
          height: 45px;
          width: 45px;
        }
        .our_core_values_text_container {
          text-align: center;
          width: 80%;
          .our_core_values_text {
            font-size: 14px;
          }
          .our_core_values_subtext {
            font-size: 13px;
          }
        }
      }

      .bottom_contact_us {
        flex-direction: column;
        gap: 16px;
        img {
          height: 45px;
          width: 45px;
        }
        .contact_us_text_container {
          width: 80%;
          text-align: center;
          .contact_us_text {
            font-size: 14px;
          }
          .contact_us_subtext {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .introduction {
    .slider_container {
      .slick_container {
        .numbers_container {
          span {
            font-size: 14px;
          }
        }
      }
      .first_slide {
        .text_container {
          h2 {
            font-size: 28px;
          }
          p {
            font-size: 15px;
          }
        }
      }
      .second_slide {
        .text_container {
          h2 {
            font-size: 28px;
          }
          p {
            font-size: 15px;
          }
        }
      }
    }
    .bottom_container {
      overflow: hidden;
      padding: 20px 16px;
      .bottom_our_core_values {
        flex-direction: column;
        gap: 16px;
        img {
          height: 45px;
          width: 45px;
        }
        .our_core_values_text_container {
          text-align: center;
          width: 80%;
          .our_core_values_text {
            font-size: 14px;
          }
          .our_core_values_subtext {
            font-size: 12px;
          }
        }
      }

      .bottom_contact_us {
        flex-direction: column;
        gap: 16px;
        img {
          height: 45px;
          width: 45px;
        }
        .contact_us_text_container {
          width: 80%;
          text-align: center;
          .contact_us_text {
            font-size: 14px;
          }
          .contact_us_subtext {
            font-size: 12px;
          }
        }
      }
    }
  }
}
