.slide_item_container {
  width: 92%;
  height: 378px;
  box-sizing: border-box;
  padding: 80px 60px;
  border-radius: 20px;
  box-shadow: 0px 4px 14px 0px rgba(206, 195, 195, 0.1);
  color: white;
  word-break: keep-all;

  span {
    font-size: 32px;
    font-weight: 800;
    line-height: 48px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 40px;
  }
}

@media (max-width: 1790px) {
  .slide_item_container {
    padding: 45px 50px;

    span {
      font-size: 28px;
      line-height: 1.4;
    }

    p {
      margin-top: 25px;
    }
  }
}

@media (max-width: 1400px) {
  .slide_item_container {
    padding: 40px 45px;
  }
}

@media (max-width: 1130px) {
  .slide_item_container {
    height: 300px;
    padding: 40px 30px;

    span {
      font-size: 24px;
      line-height: 1.4;
    }

    p {
      margin-top: 25px;
      font-size: 15px;
    }
  }
}

@media (max-width: 550px) {
  .slide_item_container {
    padding: 35px 25px;

    span {
      font-size: 18px;
    }

    p {
      margin-top: 15px;
      line-height: 1.6;
    }
  }
}
