@import "/src/color.scss";
@import "/src/common.scss";

.footer_container {
  background: $text-dark-grey;
  width: 100%;
  height: 442px;
  padding: 90px 196px;
  box-sizing: border-box;

  .logo_container {
    @include justi-between;

    button {
      all: unset;
      cursor: pointer;
      @include justi-between-align-center;
      width: 200px;
      padding: 16px 32px;
      box-sizing: border-box;
      border-radius: 80px;
      background: $point-skyblue-500;
      font-size: 17px;
      font-weight: 700;
      color: white;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }
  }

  .content_container {
    p {
      margin-top: 12px;
      color: white;
      font-size: 15px;
      font-weight: 500;
      opacity: 0.6;

      &:first-child {
        margin-top: 30px;
      }

      &:last-child {
        margin-top: 40px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .footer_container {
    padding: 90px 80px;
  }
}

@media (max-width: 768px) {
  .footer_container {
    height: auto;
    padding: 40px 25px;

    .logo_container {
      button {
        width: 145px;
        padding: 10px 20px;
        font-size: 13px;
      }
    }

    .content_container {
      p {
        font-size: 12px;

        &:first-child {
          display: flex;
          flex-direction: column;

          span {
            margin-top: 13px;
          }
        }

        &:last-child {
          margin-top: 30px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .footer_container {
    position: relative;
    height: 385px;

    .logo_container {
      flex-direction: column;

      button {
        position: absolute;
        left: 4%;
        bottom: 10%;
      }
    }
  }
}
