@import "/src/color.scss";

.system {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 100px;
  box-sizing: border-box;

  .contents_container {
    position: relative;
    width: 100%;
    display: flex;
    word-break: keep-all;

    .content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33.33333%;
      height: 628px;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -2;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        transition: background-color 0.5s ease;
        z-index: -1;
      }

      .text_container {
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 100px 60px;
        color: white;

        span {
          font-size: 32px;
          font-weight: 800;
        }

        p {
          margin-top: 80px;
          opacity: 0;
          line-height: 1.5;
          transition: opacity 0.5s ease;
        }
      }

      &:hover {
        .overlay {
          background-color: #0097ffb2;
        }

        .text_container p {
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .system {
    .contents_container {
      flex-direction: column;

      .content {
        width: 100%;
        height: 314px;

        .text_container {
          padding: 70px 80px;

          span {
            font-size: 28px;
          }

          p {
            margin-top: 50px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .system {
    padding: 60px 16px;
    .contents_container {
      .content {
        margin: 0 auto;
        width: 343px;
        height: 343px;

        .text_container {
          padding: 50px 30px;

          span {
            font-size: 20px;
          }

          p {
            font-size: 13px;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
