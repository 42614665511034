@import "/src/color.scss";
@import "/src/common.scss";

.news_container {
  padding: 160px 200px;
  box-sizing: border-box;

  .card_container {
    @include justi-center;
    gap: 30px;
    margin-top: 90px;
  }
}

@media (max-width: 1500px) {
  .news_container {
    .card_container {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 1110px) {
  .news_container {
    padding: 160px 20px;
  }
}

@media (max-width: 768px) {
  .news_container {
    padding: 60px 20px;

    .card_container {
      margin-top: 30px;
    }
  }
}
