@import "/src/color.scss";
@import "/src/common.scss";

.main_features_container {
  @include justi-between-align-center;
  width: 100%;
  background: $bg-light-grey;
  padding: 8.5% 0 8.5% 10.5%;
  box-sizing: border-box;

  .section_title_container {
    @include flex-column;
    justify-content: left;
    gap: 60px;
    padding-bottom: 104px;

    .logo_colored {
      width: 35%;
      height: 35%;
    }

    .section_title {
      p {
        color: $text-dark-grey;
        font-size: 50px;
        font-weight: 800;

        span {
          color: $point-skyblue-500;
        }
      }
    }
  }

  .slider_container {
    width: 75%;

    .prev_arrow,
    .next_arrow {
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: -5%;
      cursor: pointer;
    }

    .prev_arrow {
      left: -34%;
    }

    .next_arrow {
      left: -30%;
    }
  }
}

@media (max-width: 1790px) {
  .main_features_container {
    .section_title_container {
      .section_title {
        width: 80%;
      }
    }
  }
}

@media (max-width: 1560px) {
  .main_features_container {
    flex-direction: column;
    padding: 8.5% 7.5% 8.5% 10.5%;

    .section_title_container {
      width: 100%;
      flex-direction: column;
      gap: 30px;

      .logo_colored {
        width: 10%;
        height: 10%;
      }
    }

    .slider_container {
      width: 100%;

      .prev_arrow,
      .next_arrow {
        bottom: 110%;
      }

      .prev_arrow {
        left: 0%;
      }

      .next_arrow {
        left: 5%;
      }
    }
  }
}

@media (max-width: 1400px) {
  .main_features_container {
    padding: 8.5% 4% 8.5% 6.5%;
  }
}

@media (max-width: 1080px) {
  .main_features_container {
    padding: 8.5% 0 8.5% 8.5%;
  }
}

@media (max-width: 1000px) {
  .main_features_container {
    padding: 8.5% 0% 8.5% 5%;
  }
}

@media (max-width: 768px) {
  .main_features_container {
    padding: 8.5% 0% 8.5% 5%;

    .section_title_container {
      gap: 20px;
      padding-bottom: 90px;

      .section_title {
        p {
          font-size: 30px;
        }
      }
    }

    .slider_container {
      width: 100%;

      .prev_arrow,
      .next_arrow {
        width: 25px;
        height: 25px;
        bottom: 110%;
      }

      .next_arrow {
        left: 7%;
      }
    }
  }
}
