@import "/src/color.scss";
@import "/src/common.scss";

.section_title_container {
  @include flex-column-center;
  gap: 24px;
  word-break: keep-all;

  .sub_title {
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 800;
    line-height: 1.5;
    text-transform: uppercase;
    color: $point-skyblue-500;
  }

  .main_title {
    text-align: center;
    margin: 0;
    color: $text-dark-grey;
    font-size: 50px;
    font-weight: 800;
    text-transform: uppercase;
  }

  .detail_info {
    text-align: center;
    color: $text-mid-grey;
    font-size: 20px;
  }
}

@media (max-width: 1080px) {
  .section_title_container {
    gap: 20px;

    .sub_title {
      font-size: 18px;
    }

    .main_title {
      font-size: 35px;
    }

    .detail_info {
      font-size: 18px;
    }
  }
}

@media (max-width: 768px) {
  .section_title_container {
    gap: 14px;

    .sub_title {
      font-size: 14px;
    }

    .main_title {
      font-size: 24px;
    }

    .detail_info {
      font-size: 14px;
    }
  }
}
