@import "/src/color.scss";
@import "/src/common.scss";

.card_container {
  width: 20%;
  min-width: 340px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  .image {
    display: block;
    width: 100%;
    height: 260px;
    border-radius: 20px 20px 0 0;
    object-fit: cover;
    object-position: center;
  }

  .card_content {
    width: 100%;
    height: 224px;
    padding: 40px 30px;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
    background: $bg-light-grey;
    align-items: center;

    .category {
      color: $point-skyblue-500;
      font-size: 17px;
      font-weight: 700;
    }

    .title {
      margin-top: 14px;
      color: $text-dark-grey;
      font-size: 24px;
      font-weight: 700;
    }

    .date {
      display: block;
      margin-top: 34px;
      color: $text-mid-grey;
      font-size: 15px;
      font-weight: 400;
    }
  }

  &:hover {
    transform: translateY(-50px);
  }

  &:hover .card_content {
    background: white;
  }
}

@media (max-width: 768px) {
  .card_container {
    .image {
      border-radius: 18px 18px 0 0;
    }

    .card_content {
      padding: 24px 24px;
      height: 170px;

      .category {
        font-size: 15px;
      }

      .title {
        margin-top: 8px;
        font-size: 24px;
      }

      .date {
        margin-top: 20px;
        font-size: 13px;
      }
    }

    &:hover {
      transform: translateY(0px);
    }
  }
}
