@import "/src/color.scss";
@import "/src/common.scss";

.button_more {
  all: unset;
  @include justi-align-center;
  margin: 80px auto 0;
  width: 200px;
  height: 50px;
  border-radius: 80px;
  border: 1px solid $text-mid-grey;
  text-align: center;
  color: $text-mid-grey;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button_more:hover {
  background: $text-mid-grey;
  color: white;
}

@media (max-width: 768px) {
  .button_more {
    margin-top: 40px;
    width: 120px;
    height: 36px;
    font-size: 13px;
  }
}
