@import "/src/color.scss";
@import "/src/common.scss";

.question_container {
  @include justi-between-align-center;
  gap: 70px;
  padding: 30px 0;
  box-sizing: border-box;
  border-top: 1px solid $border-light-grey;
  border-bottom: 1px solid $border-light-grey;
  word-break: keep-all;

  .question {
    @include align-center;
    gap: 20px;

    span {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 800;
      color: $point-skyblue-500;
    }

    p {
      font-size: 20px;
      font-weight: 700;
      color: $text-dark-grey;
    }
  }

  &.active p {
    color: $point-skyblue-500;
  }
}

@media (max-width: 1000px) {
  .question_container {
    padding: 20px 0;

    .question {
      p {
        line-height: 1.5;
      }
    }
  }
}

@media (max-width: 768px) {
  .question_container {
    gap: 10px;

    .question {
      span {
        font-size: 14px;
        font-weight: 700;
      }

      p {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
